







import Vue, { PropOptions } from 'vue';

export type LogosItem = Blok & {
  image: BlokFieldAsset;
  width: string;
  height: string;
  badge: string;
  lightModeBlend: null | 'difference';
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<LogosItem>,
  },
  computed: {
    cssVars(): CSSVars {
      return {
        '--logos-image-width': this.blok?.width,
        '--logos-image-height': this.blok?.height,
        '--logos-image-blend-mode': this.blok?.lightModeBlend
          ? this.blok.lightModeBlend
          : 'unset',
      };
    },
  },
});
